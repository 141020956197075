<template>
  <div
    v-if="user.email !== ''"
    class="sm:mx-6 mt-6 py-6 px-6 sm:px-16 break-words"
  >
    <AssetTitle title="Account Details" />
    <div>
      <h1>Username: {{ user.name }}</h1>
      <h2>Email address: {{ user.email }}</h2>
    </div>
    <AssetTitle title="Verification" class="mt-16" />
    <div v-if="user.state !== 'KYC_PASS'" class="max-w-max">
      <t-button @click="verifyUser">
        Start
      </t-button>
    </div>
    <h2 v-else>Verification completed.</h2>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import * as api from '@/api';
import AssetTitle from '@/components/AssetTitle';
import { emitError, emitSucces } from '@/utils/alerts';

const PERSONA_TEMPLATE_ID = 'itmpl_PvfuvZs9pyA2MV2UUupW3oQ4';
const PERSONA_ENVIRONMENT = 'sandbox';

export default {
  name: 'Home',
  components: {
    AssetTitle,
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  mounted() {
    this.getUser();
  },
  methods: {
    ...mapActions({
      getUser: 'user/GET_USER_DATA',
    }),
    async verifyUser() {
      const [nameFirst, nameLast] = this.user.name.split(' ', 2);
      // Imported from CDN: see public/index.html
      // eslint-disable-next-line no-undef
      const client = new Persona.Client({
        templateId: PERSONA_TEMPLATE_ID,
        environment: PERSONA_ENVIRONMENT,
        prefill: {
          nameFirst,
          nameLast,
          emailAddress: this.user.email,
        },
        onReady: () => client.open(),
        onComplete: ({ inquiryId }) => {
          this.onPersonaFormComplete(inquiryId);
        },
      });
    },
    async onPersonaFormComplete(persona_inquiry_id) {
      try {
        await api.post('verify-kyc', { persona_inquiry_id });
        emitSucces('Verification successful.');
        const userData = await api.get('user');
        this.$store.dispatch('user/LOGIN_USER', userData);
      } catch (e) {
        emitError('Verification failed.');
      }
    },
  },
};
</script>
